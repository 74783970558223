//Section Blog

.section-blog {
    padding-top: 6rem;
    padding-bottom: 3rem;

    .post {
        overflow: hidden;

        .post-image {
            overflow: hidden;
            border-radius: 12px;

            img {
                width: 100%;
                height: auto;
                transform: scale(1);
                transition: transform 0.3s ease-in-out;
                border-radius: 0;
                max-height: 500px;
                object-fit: cover;
                object-position: center;

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.3s ease-in-out;
                }
            }

        }

        p {
            font-weight: 300;
            font-size: 18px;
            color: #666666;
        }

        h2 {
            font-size: calc(0.9rem + 0.1vw);
            text-transform: uppercase;
            font-weight: 500;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

    }

    .new-post {
        overflow: hidden;

        .post-image {
            overflow: hidden;
            border-radius: 0 0 12px 12px;

            img {
                width: 100%;
                height: auto;
                transform: scale(1);
                transition: transform 0.3s ease-in-out;
                border-radius: 0;
                max-height: 500px;
                object-fit: cover;
                object-position: center;

                &:hover {
                    transform: scale(1.05);
                    transition: transform 0.3s ease-in-out;
                }
            }

        }

        p {
            font-weight: 300;
        }

        h2 {
            font-size: calc(0.9rem + 0.1vw);
            text-transform: uppercase;
            font-weight: 500;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

    }

    span {
        font-size: calc(0.75rem + 0.1vw);
        text-transform: uppercase;
        font-weight: 500;
    }

    h1 {
        font-size: calc(4rem + 1vw);
        margin-bottom: 15px;
    }

    .blog-list-header{
        p{
            color: #666666;
            font-weight: 300;
        }
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
            margin-top: 2.5rem;
            margin-bottom: 1rem;
        }
    }

    .right {

        .banner-top {
            display: flex;
            padding: 0.4rem;
            justify-content: center;
            align-items: center;
            background-color: var(--brand-color-green);
            text-align: center;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            z-index: 10;
        }

        .new-post {
            margin-bottom: 3rem;

            img {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

        }

    }

}

.section-blog-list{
    padding-top: 0;
    padding-bottom: 6rem;
}
