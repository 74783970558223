html {
    --text-color: #222;
    --background-color: #e8e8e8;
    --main-wrapper-background-color: #fff;
    --brand-color-green: #F2FF93 !important;
}

body {
    padding: 1.8vw !important;
    background-color: var(--background-color) !important;
    font-family: 'DM Sans' !important;
    color: var(--text-color);
    font-size: 18px;
    line-height: 160%;
}

main {
    display: flex;
    flex-direction: column;
}

input[type="email"] {
    min-height: 3.5rem;
    margin-bottom: 0;
    padding: 1rem 1.25rem;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    background-color: #fff;
    -webkit-transition: background-color 0.2s, border 0.2s;
    transition: background-color 0.2s, border 0.2s;
    font-size: 16px;
    line-height: 140%;
    min-width: 80%;
    margin-bottom: 0.5rem;
}

#main-wrapper {
    border-radius: 1rem;
    background-color: #fff;
}

::selection {
    background-color: var(--text-color);
    color: #ffffff;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #ffffff var(--background-color);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: var(--background-color);
}

*::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 0px;
    border: 0px solid #ffffff;
}

.container-inner {
    width: 100%;
    max-width: 87.5rem;
    margin-right: auto;
    margin-left: auto;
}

a {
    text-decoration: none;
    color: var(--text-color);

    &:hover {
        color: #000;
    }
}

p {
    margin: 0;
}

.btn {
    position: relative !important;
    overflow: hidden !important;
    margin: 0;
    padding: 1rem 1.5rem !important;
    justify-content: center !important;
    background-color: transparent !important;
    border-width: 0 !important;
    border-color: transparent !important;
    background-color: var(--brand-color-green) !important;
    color: var(--text-color) !important;
    font-family: 'DM Sans' !important;
    font-size: 14px !important;
    line-height: 110% !important;
    font-weight: 600 !important;
    text-align: center !important;
    letter-spacing: 1px !important;
    text-decoration: none !important;
    text-transform: uppercase !important;

    .btn-inner {
        position: relative !important;
        z-index: 6 !important;
        display: flex !important;
        overflow: hidden !important;
        height: 1rem !important;
        flex-direction: column !important;
        gap: 0.1rem !important;

        .btn-inner-text {
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 1;
            transition: all 0.3s ease-in-out;
        }

        .btn-inner-text-hover {
            transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
            transform-style: preserve-3d;
            opacity: 0;
            transition: all 0.3s ease-in-out;
        }
    }
}

.btn:hover {
    background-color: var(--brand-color-green) !important;
    color: var(--text-color) !important;

    .btn-inner {

        .btn-inner-text {
            transform: translate3d(0px, -100%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
            opacity: 0;
        }

        .btn-inner-text-hover {
            transform: translate3d(0px, -100%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
            opacity: 1;
        }
    }
}

.btn-secondary {
    background-color: #000 !important;
    color: #fff !important;
}

.btn-secondary:hover {
    background-color: #000 !important;
    color: #fff !important;
}

.btn-transparent {
    background: none !important;
    border: none !important;
}

.btn-small-icon {
    padding: 10px !important;
    aspect-ratio: 1;
    background-color: #ffffff !important;

    .btn-inner {
        height: auto !important;
    }
}

.change-lang {
    button {
        background: none;
        padding: 0;
        border: none;

        svg {
            margin-bottom: 5px;
        }
    }
}

//Home Page
#main-wrapper {
    border-radius: 1rem;
    background-color: var(--main-wrapper-background-color)
}

//404
.section-404 {
    min-height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .btn {
        margin-top: 1rem;
    }
}

//Footer
footer {
    padding-top: 6rem;
    padding-bottom: 3rem;

    .footer-1 {
        max-width: 70%;

        p {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        img {
            width: 48px;
            height: auto;
        }

    }

    h4 {
        text-transform: uppercase;
        font-size: calc(1.2rem + 0.1vw);
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            text-transform: uppercase;
            font-weight: 500;
            font-size: calc(0.75rem + 0.1vw);
            cursor: pointer;
        }
    }

    .footer-bottom {
        margin-top: 4rem;

        p {
            text-transform: uppercase;
            font-weight: 500;
            font-size: calc(0.75rem + 0.1vw);
        }
    }
}

.socials {
    list-style: none;
    margin: 0;
    padding: 0;

    .social {
        padding: 0.4rem 0.5rem;
        align-items: center;
        background-color: #F8F8F8;
        border: 1px solid #F8F8F8;
        border-radius: 4px;
        color: #222;
        letter-spacing: 1px;
        text-transform: uppercase;
        z-index: 1;
        line-height: 1;
        font-weight: 500;
        font-size: 0.9rem;
    }
}

//Home Hero section
.section-home-hero {
    padding-top: 6rem;

    .container-inner {
        display: flex;
        gap: 4rem;

        .left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            gap: 30px;
            justify-content: space-between;

            h1 {
                font-size: 4.5rem;
                font-weight: 400;
                line-height: 100%;
            }

            .scroll-down {
                position: absolute;
                right: 0;
                bottom: 0;

                svg.mouse {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                svg.mouse-circle {
                    animation: Rotate 50s linear infinite;
                }

                @keyframes Rotate {
                    from {
                        transform: rotate(0deg)
                    }

                    to {
                        transform: rotate(800deg)
                    }
                }
            }
        }

        .right {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 20px;

            .box {
                padding: 1.5rem 2rem;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: flex-start;
                position: relative;
                overflow: hidden;

                &.green {
                    background-color: var(--brand-color-green);

                    .btn {
                        background-color: #ffffff !important;
                    }

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        top: 0;
                        left: 0;
                        opacity: 0;
                    }

                    &:hover {
                        img {
                            opacity: 1;
                            animation: Scale 10s linear infinite;
                        }

                        @keyframes Scale {
                            0% {
                                scale: 1;
                            }

                            50% {
                                scale: 1.3;
                            }

                            100% {
                                scale: 1;
                            }
                        }
                    }
                }

                &.dark {
                    background-color: #222222;
                    color: #ffffff;

                    &:before {
                        content: "";
                        background-image: url('../img/IMG_6498e.png');
                        background-size: contain;
                        background-position: center bottom;
                        background-repeat: no-repeat;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                    }

                    &:hover {

                        &:before {
                            opacity: 1;
                            transition: opacity 0.3s ease;
                        }

                        .btn {
                            background-color: var(--brand-color-green) !important;
                        }
                    }

                }

                h2 {
                    font-size: 2.7rem;
                }

                a {
                    align-self: flex-end;
                }
            }
        }

        @media (max-width:867px) {
            .right {
                display: none;
            }
        }

    }

    @media (max-width:1500px) {
        .container-inner {
            flex-direction: column;

            .box {
                aspect-ratio: 1 / 0.6;
            }
        }
    }

    .hero-banner {
        padding-top: 4rem;

        .hero-banner-img {
            position: relative;
            overflow: hidden;
            border-radius: 12px 12px 0 0;

            img {
                width: 100%;
            }
        }

        .hero-banner-bottom {
            display: flex;
            padding: 0.4rem;
            justify-content: center;
            align-items: center;
            background-color: var(--brand-color-green);
            text-align: center;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }
}


//Services Section
.section-services {
    padding-top: 10rem;

    .top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 3.75rem;

        span {
            font-size: calc(0.75rem + 0.1vw);
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    @media (max-width:991px) {
        .top {
            align-items: flex-start;
            flex-direction: column-reverse;
            margin-bottom: 2rem;
        }
    }

    .services-loop {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px;

        .services-card-wrapper {
            border-radius: 12px;
            box-shadow: 0 -10px 0 0 #fff;
            background-color: #f8f8f8;
            padding: 3rem;

            h3 {
                text-transform: uppercase;
            }

            .icons {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: nowrap;
                margin-bottom: 1.3rem;

                .icon {
                    background: #ffffff;
                    padding: 5px;
                    border-radius: 100px;
                    width: 65px;
                    height: 65px;
                    -webkit-box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: -20px;

                    img {
                        width: 45px;
                        height: 45px;
                        object-fit: contain;
                    }

                    &:first-of-type {
                        margin-left: 0;
                    }
                }
            }

            &:hover {
                background-color: var(--brand-color-green);
                transition: all 0.3s ease-in-out;
            }
        }
    }

    @media (max-width:1250px) {
        .services-loop {
            grid-template-columns: repeat(2, 1fr);

            .services-card-wrapper {
                padding: 2rem;
            }
        }
    }

    @media (max-width:900px) {
        .services-loop {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

//About me Section
.section-about-me {
    padding-top: 8%;


    .left {

        .left-inner {
            position: sticky;
            top: 3rem;

            span {
                font-size: calc(0.75rem + 0.1vw);
                text-transform: uppercase;
                font-weight: 500;
            }

            p {
                margin-bottom: 1rem;
            }
        }
    }

    .right {

        h2 {
            font-size: calc(2.5rem + 0.1vw);
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.1rem;
            line-height: 160%;

            a {
                text-decoration: underline;
            }
        }
    }

    .btn {
        display: block;
        width: 100%;
        margin-top: 1rem;
    }
}

@media (max-width:991px) {
    .section-about-me {
        padding-top: 12%;
    }
}

@media (max-width: 767.98px) {
    .section-services {
        padding-top: 3rem;


        .left {

            .left-inner {
                position: relative;
                max-width: 100%;
                top: 0;

                p {
                    margin-bottom: 5rem;
                }

                h2 {
                    font-size: calc(2rem + 0.1vw);
                    margin-bottom: 1rem;
                }
            }
        }

        .right {
            .services-card-wrapper {
                position: sticky;
                top: 2rem;
                padding: 1.25rem;
            }
        }


    }
}

//Section Get in touch
.section-git {
    padding-top: 6rem;
    padding-bottom: 6rem;

    .section-git-inner {
        background-color: var(--brand-color-green);
        padding: 3rem;
        border-radius: 12px;

        .left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        h2 {
            margin-bottom: 1rem;
            line-height: 1.2em;
        }

        img {
            margin-bottom: 2rem;
        }

        span {
            margin-top: 2rem;
            font-size: calc(0.75rem + 0.1vw);
            text-transform: uppercase;
            font-weight: 500;
        }
    }

}

@media (max-width: 1199.98px) {
    #main-wrapper {

        .section-home-hero {
            padding-top: 6rem;

            .container-inner {

                .left {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    h1 {
                        font-size: 5rem;
                    }

                    .scroll-down {
                        position: relative;

                        svg.mouse {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    @media (max-width:768px) {
                        .scroll-down {
                            display: none;
                        }
                    }
                }

            }

        }
    }
}

@media (max-width: 991.98px) {
    #main-wrapper {

        .section-home-hero {

            .container-inner {

                .left {
                    h1 {
                        font-size: 2rem;
                    }
                }

            }

        }
    }
}

//Header
header {
    padding-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;


    nav {
        display: flex;
        align-items: center;
        grid-column-gap: 2px;

        a {
            display: flex;
            padding: .75rem 1rem;
            align-items: center;
            grid-column-gap: 2px;
            grid-row-gap: 2px;
            border-radius: 6px;
            transition: background-color .3s;
            color: var(--text-color);
            font-size: 0.9rem;
            line-height: 120%;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;

            &:hover {
                background-color: #f8f8f8;
                color: var(--text-color);
            }
        }
    }
}

//Header tablet and tablet/mobile menu
@media (max-width: 991.98px) {
    header {
        padding-top: 1.25rem;

        .navbar-menu {
            position: absolute;
            left: 2rem;
            right: 2rem;
            top: 3rem;
            margin-top: 3rem;
            padding: 1.5rem;
            border-radius: 12px;
            background-color: #f8f8f8;
            display: flex;
            flex-direction: column;
            grid-row-gap: 0.75rem;
            align-items: flex-start;
            z-index: 99;
        }

        .btn-navbar {
            display: none;
        }

        .mobile-navbar-menu {
            display: flex;
            align-items: center;
            gap: 20px;
            flex-direction: row-reverse;
        }
    }
}

.carousel {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 70px;
    grid-row-gap: 70px;

    .swiper-wrapper {
        align-items: center;
    }

    img {
        width: 100%;
        object-fit: contain;
        max-height: 120px;
        max-width: 190px;
        filter: grayscale(1);
        transition: filter 0.3s ease;

        &:hover {
            filter: grayscale(0);
            transition: filter 0.3s ease;
        }
    }

}

.swiper-pagination-bullet-active {
    background: var(--text-color) !important;
}

.swiper-custom-pagination-container {
    display: none;
    align-items: center;
    position: relative;

    .swiper-custom-pagination {
        position: absolute;
        bottom: -40px !important;
    }
}

@media (max-width:768px) {
    .swiper-custom-pagination-container {
        display: flex;
    }
}

// Work
#work {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    margin: 5rem 0 0 0;

    .work-item {
        text-transform: uppercase;

        button {
            background-color: #222222;
            color: #E8E8E8;
            border-radius: 11px;
            padding: 8px;
            line-height: 1;
            border: none;
            text-transform: uppercase;
        }

        img {
            border: 1px solid #0000009c;
            border-radius: 22px;
            margin-bottom: 30px;
            width: 100%;
            height: 500px;
            object-fit: cover;
        }

        h4 {
            font-size: calc(2rem + .9vw);
        }

        p {
            font-weight: 500;
            margin-bottom: 30px;
            line-height: 1.1;
        }

        .work-buttons {
            display: flex;
            align-items: center;
            gap: 16px;
            flex-wrap: wrap;

            .collab {
                background-color: var(--brand-color-green);
                color: #222222;
            }
        }
    }

    /* Skeleton loading styles */
    .skeleton {
        opacity: 0.6;
        pointer-events: none;
    }

    .skeleton-img {
        background-color: #e0e0e0;
        border-radius: 22px;
        width: 100%;
        height: 500px;
        object-fit: cover;
        margin-bottom: 30px;
    }

    .skeleton-title {
        background-color: #e0e0e0;
        width: 40%;
        height: 40px;
        border-radius: 5px;
        margin-bottom: 30px;
    }

    .skeleton-buttons {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .skeleton-button {
        background-color: #e0e0e0;
        height: 40px;
        width: 150px;
        border-radius: 5px;
    }

    /* Adjust for responsiveness */
    @media (max-width:970px) {
        .skeleton-img {
            height: 210px;
        }
    }

}

@media (max-width:970px) {
    #work {
        grid-template-columns: repeat(1, 1fr);
        margin: 4rem 0 0 0;


        .work-item {

            img {
                height: 210px;
            }

            .work-buttons {
                font-size: 1rem;
            }

            p {
                margin-bottom: 10px;
                font-size: 1rem;
            }
        }

    }
}

.home-portfolio {
    padding-bottom: 8%;

    #work {
        margin-top: 2rem;
    }

    .top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 9%;
        margin-bottom: 1rem;

        span {
            font-size: calc(0.75rem + 0.1vw);
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    @media (max-width:991px) {
        .top {
            align-items: flex-start;
            flex-direction: column-reverse;
            margin-top: 6rem;
        }
    }
}

@media (max-width:991px) {
    .home-portfolio {
        padding-bottom: 12%;
    }
}



//Bootstrap Modal
.modal {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 550px;
    --bs-modal-padding: 1rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-color: ;
    --bs-modal-bg: #fff;
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: 1px;
    --bs-modal-border-radius: 0.5rem;
    --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-modal-inner-border-radius: calc(0.5rem - 1px);
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem 1rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: 1px;
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: 1px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}

.modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity);
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
    background: transparent;
    border: none;
    font-size: 2rem;
}

.modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height);
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
}

.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
    margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
    .modal {
        --bs-modal-margin: 1.75rem;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }

    .modal-dialog {
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto;
    }

    .modal-sm {
        --bs-modal-width: 300px;
    }
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        --bs-modal-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        --bs-modal-width: 1140px;
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
    border-radius: 0;
}

.modal-fullscreen .modal-body {
    overflow-y: auto;
}

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0;
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto;
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0;
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto;
    }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0;
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto;
    }
}

@media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0;
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto;
    }
}

@media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0;
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto;
    }
}

//Bootstrap Modal End

//Lead Contact Form
.lead-modal {

    p {
        margin-bottom: 10px;
    }

    .sendStatus {
        margin-top: 10px;
        display: block;
    }

    .lead-contact-form {
        input {
            margin-right: 10px;
        }

        a {
            text-decoration: underline;
        }

        .email-and-button {
            display: flex;
            align-items: center;
            gap: 10px;

            input {
                width: calc(100% - 175px);
            }

            button {
                height: 56px;
                width: 165px;
            }
        }

        .email-and-button {
            margin-bottom: 10px;

            input {
                min-width: auto;
                margin: 0;
            }
        }
    }
}

.section-hero {
    padding-top: 6rem;

    .container-inner {
        h1 {
            font-size: 5.5rem;
            font-weight: 400;
            line-height: 100%;
        }

        p {
            font-size: 1.5rem;
            line-height: 160%;

            a {
                text-decoration: underline;
            }
        }
    }
}

section.legal {
    margin: 5rem 0 3rem 0;

    p {
        margin-bottom: 1rem;
    }
}

.szh-accordion {
    .szh-accordion__item {
        background-color: #F8F8F8;
        padding: 32px;
        border-radius: 12px;
        margin-bottom: 1rem;

        button {
            width: 100%;
            background: none;
            text-align: left;
            border: none;
            padding: 0;
            outline: none;
        }

        .szh-accordion__item-content {
            margin-top: 1rem;
            transition: height .25s cubic-bezier(0, 0, 0, 1);
        }
    }
}

.checklist-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E8E8E8;
    max-width: 350px;

    span{
        line-height: 1;
    }
}

.check-icon {
    color: #000;
    /* Adjust color as needed */
    font-size: 1.5rem;
    margin-right: 10px;
}

.font-size-lg {
    font-size: 4.6rem;
}

@media (max-width: 1199.98px) {
    .font-size-lg {
        font-size: 5rem;
    }
}

@media (max-width: 991.98px) {
    .font-size-lg {
        font-size: 2rem;
    }
}

.sprava-webstranok {
    margin-top: 6rem;
    max-width: 1400px;

    .pricing{
        background-color: #F8F8F8;
        padding: 32px;
        border-radius: 12px
    }
}

@import url('./cookiebar.scss');