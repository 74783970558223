//Section Post
.section-post {
    padding-top: 6rem;

    .post-header {
        h1 {
            font-size: calc(3rem + 1.5vw);
            margin-bottom: 2rem;
            text-align: center;
        }

        span {
            font-size: calc(0.75rem + 0.1vw);
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 1px;
            color: rgba(34, 34, 34, .6);
        }

        p {
            color: #222;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 0.9rem;
            margin-bottom: 1rem;
        }

        @media (max-width: 1000px) {
            h1 {
                font-size: calc(1.5rem + 1.5vw);
            }
        }
    }

    .banner-top {
        display: flex;
        padding: 0.4rem;
        justify-content: center;
        align-items: center;
        background-color: var(--brand-color-green);
        text-align: center;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        z-index: 10;
    }

    .single-post-content-container {
        display: flex;
        max-width: 1000px;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 60px;
        align-items: flex-start;

        .single-post-content {
            max-width: 850px;

            .cas-citania {
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                margin-top: 20px;
            }

            h2 {
                font-size: 48px;
            }

            @media (max-width: 1000px) {
                h2 {
                    font-size: calc(1.5rem + 1.5vw);
                }
            }

            p {
                margin-bottom: 20px;
                color: #222222;
                font-weight: 300;
            }

            img {
                width: 100%;
                border-radius: 12px;
                object-fit: cover;
                margin-bottom: 2rem;
            }


            @media (max-width: 1000px) {

                p {
                    font-size: 18px;
                }
            }

            .single-post-conslusion {
                background-color: #F8F8F8;
                padding: 48px;
                border-radius: 12px;
                margin: 1rem 0;

                .author {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 15px;

                    .author-info {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        span {
                            color: #222222;
                            font-size: 20px;
                            line-height: 1;
                        }

                        a {
                            color: #666666;
                            font-size: 14px;
                            text-transform: uppercase;
                            font-weight: 500;
                            line-height: 1;
                        }
                    }

                    img {
                        width: 45px;
                        height: auto;
                        margin: 0;
                    }
                }
            }

            @media (max-width: 768px) {
                .single-post-conslusion {
                    padding: 35px;
                }
            }
        }
    }

    @media (max-width: 1132px) {
        .single-post-content-container {
            max-width: 100%;
            gap: 30px;

            .single-post-content {
                max-width: 100%;
            }
        }
    }

    .section-git {
        padding-top: 3rem;
    }

    .single-post-image {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        overflow: hidden;
        margin-bottom: 5rem;

        img {
            border-radius: 0px;
            width: 100%;
            height: 40vw;
            max-height: 700px;
            object-fit: cover;
            margin: 0;
        }
    }

    @media (max-width: 1000px) {
        .single-post-image {
            margin-bottom: 2rem;
        }
    }

    .single-post-share {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        position: sticky;
        top: 10px;

        span {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            color: #666666;
        }

        .share-buttons {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            .share {
                padding: 0.7rem;
                align-items: center;
                background-color: #F8F8F8;
                border: 1px solid #F8F8F8;
                border-radius: 4px;
                color: #222;
                letter-spacing: 1px;
                text-transform: uppercase;
                z-index: 1;
                line-height: 1;
                font-weight: 500;
                font-size: 0.9rem;
            }
        }
    }

    @media (max-width: 1132px) {
        .single-post-share {
            align-items: flex-start;
            position: relative;

            .share-buttons {
                flex-direction: row;
            }
        }
    }
}

@media (max-width: 1000px) {
    .section-post {
        padding-top: 3rem;
    }
}
