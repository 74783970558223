//Cookie
#CybotCookiebotDialog *,
#CybotCookiebotDialogBodyUnderlay * {
    background: transparent;
    box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    font-size: 15px;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

#CybotCookiebotDialog #CybotCookiebotDialogNav :after,
#CybotCookiebotDialog #CybotCookiebotDialogNav :before {
    content: none;
}

#CybotCookiebotDialog label {
    width: auto;
}

#CybotCookiebotDialogBodyUnderlay {
    background-color: #000;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: opacity .5s ease;
    width: 100%;
    z-index: 2147483630;
}

#CybotCookiebotDialog.CybotCookiebotDialogActive+#CybotCookiebotDialogBodyUnderlay {
    opacity: .75;
    pointer-events: auto;
}

#CybotCookiebotDialog {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 32px 68px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    color: #141414 !important;
    font-family: sans-serif;
    font-size: 15px;
    height: auto;
    left: 50%;
    letter-spacing: .1px;
    line-height: 24px;
    max-height: calc(100% - 16px);
    overflow: hidden;
    position: fixed;
    text-align: initial;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .5s ease;
    transition-property: width, max-width, top, bottom, left, opacity;
    width: calc(100% - 16px);
    z-index: 2147483631;
}

#CybotCookiebotDialog ol,
#CybotCookiebotDialog ul {
    list-style-position: inside;
}

#CybotCookiebotDialog .CybotCookiebotDialogContentWrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
}

:root #CybotCookiebotDialog,
:root #CybotCookiebotDialog #CybotCookiebotDialogTabContent,
_:-ms-fullscreen {
    height: 100%;
}

:root #CybotCookiebotDialogBody,
:root #CybotCookiebotDialogTabContent .CybotCookiebotDialogTabPanel,
_:-ms-fullscreen {
    flex: auto;
}

@media screen and (min-width:601px) {
    #CybotCookiebotDialog {
        max-width: 900px;
    }
}

@media screen and (min-width:1280px) {
    #CybotCookiebotDialog {
        left: 50%;
        max-height: 80vh;
        top: 0;
        transform: translate(-50%);
    }

    #CybotCookiebotDialog.CybotEdge {
        box-shadow: 0 30px 70px rgba(0, 0, 0, .3);
        height: auto;
        max-height: 70vh;
        max-width: 100vw;
        min-width: 100vw;
        padding: 24px;
        width: 100vw;
    }

    #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogContentWrapper {
        flex-direction: row;
        margin: 0 auto;
        max-width: 1600px;
        position: relative;
    }

    #CybotCookiebotDialog[data-template=bottom] {
        top: 100%;
        transform: translate(-50%, -100%);
    }

    #CybotCookiebotDialog[data-template=pushdown],
    #CybotCookiebotDialog[data-template=slidedown] {
        top: -100%;
        transform: translate(-50%);
    }

    #CybotCookiebotDialog[data-template=pushdown].CybotCookiebotDialogActive,
    #CybotCookiebotDialog[data-template=slidedown].CybotCookiebotDialogActive {
        top: 0;
    }

    #CybotCookiebotDialog[data-template=slideup] {
        top: 200%;
        transform: translate(-50%, -100%);
    }

    #CybotCookiebotDialog[data-template=slideup].CybotCookiebotDialogActive {
        top: 100%;
    }

    #CybotCookiebotDialog[data-template=overlay],
    #CybotCookiebotDialog[data-template=popup] {
        opacity: 0;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    #CybotCookiebotDialog[data-template=overlay].CybotCookiebotDialogActive,
    #CybotCookiebotDialog[data-template=popup].CybotCookiebotDialogActive {
        opacity: 1;
    }
}

#CybotCookiebotDialogHeader {
    align-items: center;
    border-bottom: 1px solid #D6D6D6;
    display: flex;
    padding: 1em;
    width: 100%;
}

#CybotCookiebotDialogHeaderLogosWrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
}

#CybotCookiebotDialogPoweredbyCybot,
#CybotCookiebotDialogPoweredbyLink {
    align-items: center;
    display: flex;
    width: 50%;
}

#CybotCookiebotDialogPoweredbyCybot {
    justify-content: flex-end;
    width: 175px;
}

#CybotCookiebotDialogPoweredbyImage {
    max-height: 1.5em;
    width: auto;
}

#CybotCookiebotDialogPoweredbyCybot svg {
    fill: #141414;
    display: none;
    height: 1.733em;
    max-width: 163px;
    opacity: .6;
}

#CybotCookiebotDialogPoweredByText {
    display: block;
    margin-top: .5em;
    opacity: .6;
    text-align: center;
}

#CybotCookiebotDialogPoweredByText a {
    color: #141414 !important;
    direction: ltr;
    display: block;
    font-size: .867em;
    letter-spacing: .25px;
    line-height: 19px;
    text-decoration: none;
}

#CybotCookiebotDialogPoweredByText a span {
    display: inline-block;
    font-weight: 700;
}

@media screen and (min-width:601px) {
    #CybotCookiebotDialogPoweredByText {
        margin-top: 1em;
    }
}

@media screen and (min-width:1280px) {
    #CybotCookiebotDialogHeader {
        padding: 1.5em;
    }

    #CybotCookiebotDialogPoweredbyImage {
        max-height: 2.125em;
    }

    #CybotCookiebotDialogPoweredbyCybot svg {
        display: block;
    }

    #CybotCookiebotDialogPoweredByText {
        display: none;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogHeader {
        align-items: center;
        align-self: stretch;
        border: none;
        justify-content: space-between;
        padding: 0;
        width: 210px;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogHeaderLogosWrapper {
        align-self: stretch;
        flex-direction: column;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogPoweredbyLink {
        flex: 1;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogPoweredbyImage {
        height: auto;
        max-height: 6em;
        max-width: 100%;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogPoweredbyCybot {
        height: 2em;
        margin-top: 2em;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogPoweredbyCybot,
    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogPoweredbyLink {
        justify-content: center;
    }
}

#CybotCookiebotDialogNav {
    border-bottom: 1px solid #D6D6D6;
    position: relative;
    width: 100%;
}

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogNav {
    display: none;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItems {
    display: flex;
    list-style: none;
    overflow-x: auto;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItems::-webkit-scrollbar {
    height: 0;
    width: 0;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItem {
    flex: 1 1 0%;
    margin: 0 auto;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
    border-bottom: 1px solid #ffffff;
    color: #141414 !important;
    display: block;
    font-weight: 600;
    height: 100%;
    outline-offset: -5px;
    padding: 1em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
    color: #141414 !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-bottom: 1px solid #222 !important;
    color: #141414 !important;
}

@media screen and (min-width:1280px) {
    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
        border-width: 3px;
    }
}

#CybotCookiebotDialogTabContent {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: auto;
    width: 100%;
}

#CybotCookiebotDialogTabContent .CybotCookiebotScrollContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogTabPanel {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}

#CybotCookiebotDialogSpecialFeaturesText ul {
    margin: .25em 0 .25em 2em;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogSpecialFeaturesText ul {
    margin: .25em 2em .25em 0;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer {
    border-bottom: 1px solid #D6D6D6;
    height: 100%;
    min-height: 20vh;
    padding: .375em;
    position: relative;
}

#CybotCookiebotDialog .CybotCookiebotScrollArea {
    height: 100%;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyBottomWrapper {
    width: 100%;
}

@media screen and (min-width:601px) {
    #CybotCookiebotDialog .CybotCookiebotScrollContainer {
        display: flex;
        min-height: auto;
    }
}

@media screen and (min-width:1280px) {
    #CybotCookiebotDialog.CybotEdge .CybotCookiebotScrollContainer {
        border-bottom: none;
        display: none;
    }

    #CybotCookiebotDialog.CybotEdge.CybotMultilevel .CybotCookiebotScrollContainer {
        width: calc(100% - 286px - 1.5em);
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogTabContent {
        flex: 1;
        margin-left: 1.5em;
        overflow: visible;
    }

    #CybotCookiebotDialog.CybotEdge[dir=rtl] #CybotCookiebotDialogTabContent {
        margin-left: 0;
        margin-right: 1.5em;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogTabContent .CybotCookiebotDialogTabPanel {
        overflow: visible;
    }

    #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogBodyBottomWrapper {
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 1.5em;
        padding-left: .375em;
    }

    #CybotCookiebotDialog.CybotEdge[dir=rtl] .CybotCookiebotDialogBodyBottomWrapper {
        padding-left: 0;
        padding-right: .375em;
    }

    #CybotCookiebotDialog.CybotEdge.CybotMultilevel .CybotCookiebotDialogBodyBottomWrapper {
        border-top: 1px solid #D6D6D6;
        padding-top: 1em;
    }
}

@media screen and (min-width:1510px) {
    #CybotCookiebotDialog.CybotEdge:not([lang=ta]):not([lang=bg]):not([lang=is]):not([lang=el]) #CybotCookiebotDialogTabContent {
        margin: 0 1.5em;
    }

    #CybotCookiebotDialog.CybotEdge:not([lang=ta]):not([lang=bg]):not([lang=is]):not([lang=el]) .CybotCookiebotScrollContainer {
        width: auto;
    }

    #CybotCookiebotDialog.CybotEdge:not([lang=ta]):not([lang=bg]):not([lang=is]):not([lang=el]) .CybotCookiebotDialogBodyBottomWrapper {
        border-top: none;
        padding-top: 0;
    }
}

#CybotCookiebotDialog .CybotCookiebotFader {
    bottom: 0;
    height: 5em;
    left: 1px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity .3s;
    width: calc(100% - 1em);
    z-index: 10;
}

#CybotCookiebotDialog[dir=rtl] .CybotCookiebotFader:not(.CybotCookiebotFaderLeft) {
    left: auto;
    right: 1px;
}

#CybotCookiebotDialog .CybotCookiebotFader.CybotCookiebotDialogActive {
    opacity: 1;
}

#CybotCookiebotDialog .CybotCookiebotFaderLeft,
#CybotCookiebotDialog .CybotCookiebotFaderRight {
    height: 100%;
    width: 3.5em;
}

#CybotCookiebotDialog .CybotCookiebotFaderLeft {
    left: 0;
}

#CybotCookiebotDialog .CybotCookiebotFaderRight {
    left: auto;
    right: 0;
}

@media screen and (min-width:1280px) {
    #CybotCookiebotDialog .CybotCookiebotFader:not(.CybotCookiebotFaderLeft):not(.CybotCookiebotFaderRight) {
        width: calc(100% - 1.5em);
    }
}

#CybotCookiebotDialogBody {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
    display: flex;
    flex-direction: column;
    max-height: 18em;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContent {
    max-height: inherit;
}

#CybotCookiebotDialog h2 {
    font-weight: 600;
    letter-spacing: .25px;
    line-height: 1.6em;
    margin-bottom: .5em;
}

#CybotCookiebotDialogBodyContent {
    flex: 1;
    height: 100%;
    letter-spacing: .5px;
    line-height: 1.6em;
    max-height: 100%;
    overflow-x: hidden;
    padding: 1.625em .625em;
}

#CybotCookiebotDialogDetailBulkConsent {
    border: 1px solid #D6D6D6;
    border-radius: .5em;
    margin: 0 .625em 1em;
    padding: 1em;
}

#CybotCookiebotDialogDetailBulkConsent:before {
    border-top: 1px solid #D6D6D6;
    content: "";
    width: 100%;
}

#CybotCookiebotDialogDetailBulkConsentLink {
    color: #141414 !important;
    display: block;
    font-weight: 600;
    line-height: 1.6em;
    margin-bottom: .625em;
    text-decoration: none;
}

#CybotCookiebotDialogDetailBulkConsentLink:hover {
    color: #141414 !important;
}

#CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
    background-color: #D6D6D6;
    border-radius: 5em;
    display: inline-block;
    font-size: .8em;
    font-weight: 700;
    letter-spacing: .25px;
    line-height: 1.2em;
    margin-left: .5em;
    padding: .25em .5em;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
    margin-left: 0;
    margin-right: .5em;
}

#CybotCookiebotDialogDetailBulkConsentListWrapper {
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid #D6D6D6;
    border-radius: .5em;
    margin-top: 1.5em;
    padding: 1em;
}

#CybotCookiebotDialogDetailBulkConsentListWrapper>span {
    border-bottom: 1px solid #D6D6D6;
    display: block;
    font-weight: 600;
    padding-bottom: 1em;
}

#CybotCookiebotDialogDetailBulkConsentList {
    margin-top: 1em;
}

#CybotCookiebotDialogDetailFooter {
    letter-spacing: .3px;
    line-height: 1.6em;
    padding: 0 1em 1em;
}

#CybotCookiebotDialogDetailFooter a {
    color: #141414;
}

#CybotCookiebotDialogDetailBulkConsentList dt:not(:last-of-type) {
    margin-bottom: .5em;
}

@media screen and (min-width:1280px) {
    #CybotCookiebotDialog #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
        display: flex;
        flex: auto;
        height: auto;
        max-height: 15.5em;
        overflow: hidden;
    }

    #CybotCookiebotDialogBodyContent {
        padding: 1.125em;
    }

    #CybotCookiebotDialogDetailBulkConsent {
        margin: 0 2em 1em;
        padding: 1.5em;
    }

    #CybotCookiebotDialogDetailFooter {
        padding: 0 2em 1.125em;
    }

    #CybotCookiebotDialogDetailBulkConsentListWrapper {
        margin-top: 2em;
        padding: 1.5em;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBody {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyContent {
        height: auto;
        padding: 0 1.125em 0 0;
    }

    #CybotCookiebotDialog[dir=rtl].CybotEdge #CybotCookiebotDialogBodyContent {
        padding-left: 1.125em;
        padding-right: 0;
    }
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeader {
    font-weight: 600;
    line-height: 1.6em;
    margin-bottom: 1em;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABWrapper {
    border: 2px solid #D6D6D6;
    border-radius: 1em;
    margin: 1.5em 0 1em;
    padding: 1em;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABWrapper:last-of-type {
    margin-bottom: 0;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainer,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainerCollapsed {
    background-color: rgba(0, 0, 0, 0.05);
    border: 2px solid #D6D6D6;
    border-radius: 1em;
    margin-bottom: .5em;
    padding: .5em 1em 1em;
    position: relative;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainer:last-of-type,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainerCollapsed:last-of-type {
    margin-bottom: 0;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonWrapper {
    align-items: center;
    display: flex;
    font-weight: 600;
    margin: .5em 2em 0 0;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonWrapper {
    margin: .5em 0 0 2em;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonWrapper:nth-of-type(2) {
    margin-right: 0;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonWrapper:nth-of-type(2) {
    margin-left: 0;
    margin-right: 0;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs a+.CybotCookiebotDialogBodyLevelButtonIABContainerTogglesWrapper .CybotCookiebotDialogBodyLevelButtonWrapper:nth-of-type(2) {
    margin-right: 3.5em;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs a+.CybotCookiebotDialogBodyLevelButtonIABContainerTogglesWrapper .CybotCookiebotDialogBodyLevelButtonWrapper:nth-of-type(2) {
    margin-left: 3.5em;
    margin-right: 0;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABDescription {
    border-top: 1px solid #D6D6D6;
    margin-top: 1em;
    padding-top: 1em;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABDescription a {
    align-items: center;
    display: inline-flex;
    margin-top: .5em;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABDescription .CybotCookiebotDialogBodyLevelButtonIABBullet {
    margin: 1em 0 0 1em;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABDescription .CybotCookiebotDialogBodyLevelButtonIABBullet {
    margin: 1em 1em 0 0;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainerCollapsed .CybotCookiebotDialogBodyLevelButtonIABDescription {
    display: none;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainer .CybotCookiebotDialogBodyLevelButtonIABDescription,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABDescription.CybotCookiebotDialogShow {
    display: block;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainerToggleHide,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainerToggleShow {
    display: block;
    height: 2em;
    position: absolute;
    right: 1.4em;
    width: 2em;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainerToggleHide,
#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainerToggleShow {
    left: 1.4em;
    right: auto;
}

#CybotCookiebotDialogDetailBodyContentIABv2PartnersBody .CybotCookiebotDialogBodyLevelButtonIABContainer:before,
#CybotCookiebotDialogDetailBodyContentIABv2PartnersBody .CybotCookiebotDialogBodyLevelButtonIABContainerCollapsed:before {
    border-style: solid;
    border-width: .15em .15em 0 0;
    content: "";
    display: inline-block;
    height: .5em;
    position: absolute;
    right: 2em;
    top: 1.3em;
    transform: rotate(-225deg);
    width: .5em;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2PartnersBody .CybotCookiebotDialogBodyLevelButtonIABContainer:before,
#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2PartnersBody .CybotCookiebotDialogBodyLevelButtonIABContainerCollapsed:before {
    left: 2em;
    right: auto;
}

#CybotCookiebotDialogDetailBodyContentIABv2PartnersBody .CybotCookiebotDialogBodyLevelButtonIABContainer:before {
    top: 1.6em;
    transform: rotate(-45deg);
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyIABIntroContainer {
    padding-bottom: 1em;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABLabel {
    line-height: 1.5em;
}

@media screen and (min-width:1280px) {
    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABWrapper {
        padding: 1.5em;
    }

    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeader {
        align-items: center;
        border-bottom: 1px solid #D6D6D6;
        display: flex;
        margin-bottom: 1.5em;
        padding-bottom: 1.5em;
    }

    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyIABIntroContainer {
        padding-bottom: 1.5em;
    }

    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainerTogglesWrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainer,
    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABContainerCollapsed {
        padding: 1em 1.5em 1.5em;
    }

    #CybotCookiebotDialogDetailBodyContentIABv2PartnersBody .CybotCookiebotDialogBodyLevelButtonIABContainer:before,
    #CybotCookiebotDialogDetailBodyContentIABv2PartnersBody .CybotCookiebotDialogBodyLevelButtonIABContainerCollapsed:before {
        top: 1.8em;
    }

    #CybotCookiebotDialogDetailBodyContentIABv2PartnersBody .CybotCookiebotDialogBodyLevelButtonIABContainer:before {
        top: 2.1em;
    }

    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABDescription {
        width: 100%;
    }
}

#CybotCookiebotDialogDetailBodyContentTextAbout {
    padding: 1.625em .625em;
}

@media screen and (min-width:1280px) {
    #CybotCookiebotDialogDetailBodyContentTextAbout {
        padding: 1.125em;
    }
}

#CybotCookiebotDialogFooter {
    padding: 1em;
    width: 100%;
}

#CybotCookiebotDialogBodyButtons {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

@media screen and (min-width:601px) {
    #CybotCookiebotDialogBodyButtons {
        align-items: center;
        flex-direction: row;
    }
}

@media screen and (min-width:1280px) {
    #CybotCookiebotDialog #CybotCookiebotDialogFooter {
        padding: 1em 1.5em 1.5em;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter {
        padding: 0;
        width: auto;
    }

    #CybotCookiebotDialog.CybotEdge.CybotMultilevel #CybotCookiebotDialogFooter {
        position: absolute;
        right: 0;
        top: 0;
    }

    #CybotCookiebotDialog.CybotEdge[dir=rtl] #CybotCookiebotDialogFooter {
        left: 0;
        right: auto;
    }

    #CybotCookiebotDialog.CybotEdge.CybotMultilevel.CybotCloseButtonEnabled #CybotCookiebotDialogFooter {
        right: 51px;
    }

    #CybotCookiebotDialog.CybotEdge[dir=rtl].CybotCloseButtonEnabled #CybotCookiebotDialogFooter {
        left: 51px;
        right: auto;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyButtons {
        align-items: flex-end;
        flex-direction: column-reverse;
        max-width: 286px;
    }
}

@media screen and (min-width:1510px) {
    #CybotCookiebotDialog.CybotEdge:not([lang=ta]):not([lang=bg]):not([lang=is]):not([lang=el]) #CybotCookiebotDialogFooter {
        position: relative;
        right: 0;
    }

    #CybotCookiebotDialog.CybotEdge[dir=rtl]:not([lang=ta]):not([lang=bg]):not([lang=is]):not([lang=el]) #CybotCookiebotDialogFooter {
        left: 0;
        position: relative;
        right: auto;
    }
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer>div:first-of-type::-webkit-scrollbar {
    width: .25em;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer>div:first-of-type::-webkit-scrollbar-track {
    background: #f2f2f2;
    border-radius: .313em;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer>div:first-of-type::-webkit-scrollbar-thumb {
    background: #141414;
    border-radius: .313em;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer>div:first-of-type:focus {
    outline: none;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer>div:first-of-type:focus::-webkit-scrollbar-thumb {
    background: #222 !important
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer>div:first-of-type {
    scrollbar-track-color: #f2f2f2;
    scrollbar-face-color: #141414;
    scrollbar-color: #141414 #f2f2f2;
    scrollbar-width: thin;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer>div:first-of-type:focus {
    scrollbar-color: #222 !important #f2f2f2;
}

#CybotCookiebotDialog #CybotCookiebotBannerCloseButtonE2E,
#CybotCookiebotDialog .CybotCookiebotBannerCloseButton {
    fill: #141414;
    border: none;
    display: none;
}

#CybotCookiebotDialog.CybotCloseButtonEnabled .CybotCookiebotBannerCloseButton {
    display: flex;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
}

#CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton:not(:last-of-type),
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
    margin-bottom: .5em;
}

#CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyContentControlsWrapper {
    align-items: center;
    display: flex;
    margin-bottom: .5em;
}

#CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyContentControlsWrapper .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
    margin-right: 1em;
}

#CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyContentControlsWrapper .CybotCookiebotDialogBodyLevelButtonDescription {
    font-weight: 600;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    background-color: transparent;
    border: 2px solid #222 !important;
    border-radius: .25em;
    color: #141414 !important;
    display: block;
    font-weight: 600;
    padding: 1em;
    text-align: center;
    user-select: none;
    width: 100%;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:hover {
    opacity: .85;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    background-color: transparent;
    border-color: #222 !important;
    color: #141414 !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background-color: #222 !important;
    border-color: #222 !important;
    color: #fff !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize {
    align-items: center;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize .CybotCookiebotDialogArrow {
    border-style: solid;
    border-width: .15em .15em 0 0;
    display: inline-block;
    height: .5em;
    margin-left: 1em;
    transform: rotate(45deg);
    vertical-align: baseline;
    width: .5em;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize .CybotCookiebotDialogArrow {
    margin-left: 0;
    margin-right: 1em;
    transform: rotate(-135deg);
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggle {
    border-bottom: 1px solid #D6D6D6;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 1em 0 1.5em auto;
    padding-bottom: 1.5em;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderButton,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleDeselectVendorsLink,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleSelectVendorsLink {
    align-items: center;
    background-color: transparent;
    border: 2px solid #222 !important;
    border-radius: .25em;
    color: #141414 !important;
    display: flex;
    font-weight: 600;
    padding: .75em 1em;
    text-decoration: none;
    user-select: none;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderButton:hover,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleDeselectVendorsLink:hover,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleSelectVendorsLink:hover {
    opacity: .85;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderButton.select,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleSelectVendorsLink {
    background-color: #222 !important;
    border-color: #222 !important;
    color: #ffffff;
    margin-left: .5em;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderButton.select,
#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggleSelectVendorsLink {
    margin-left: 0;
    margin-right: .5em;
}

@media screen and (min-width:601px) {
    #CybotCookiebotDialog .CybotCookiebotBannerCloseButton {
        margin-left: 2.313rem;
    }

    #CybotCookiebotDialog[dir=rtl] .CybotCookiebotBannerCloseButton {
        margin-left: auto;
        margin-right: 2.313rem;
    }

    #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
        align-items: stretch;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
    }

    #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton:not(:last-of-type),
    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton,
    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
        margin-bottom: 0;
        width: 33%;
    }

    #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyContentControlsWrapper:not(.CybotCookiebotDialogHide)+#CybotCookiebotDialogBodyButtonsWrapper .CybotCookiebotDialogBodyButton {
        max-width: 286px;
        width: 100%;
    }

    #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton:not(:last-of-type) {
        margin-right: .5em;
    }

    #CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:last-of-type) {
        margin-left: .5em;
        margin-right: 0;
    }

    #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyContentControlsWrapper {
        margin-bottom: 0;
        margin-right: 1em;
    }
}

@media screen and (min-width:1280px) {
    #CybotCookiebotDialog.CybotEdge .CybotCookiebotBannerCloseButton {
        display: none;
    }

    #CybotCookiebotDialog.CybotEdge.CybotCloseButtonEnabled #CybotCookiebotBannerCloseButtonE2E {
        display: flex;
    }

    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggle {
        border-bottom: none;
        justify-content: flex-start;
        margin: 0 0 0 auto;
        padding: 0;
    }

    #CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonIABHeaderToggle {
        margin-left: 0;
        margin-right: auto;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
        flex: auto;
        flex-direction: column-reverse;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
        max-width: none;
        width: 286px;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:not(:first-of-type) {
        margin-bottom: .5em;
    }
}

#CybotCookiebotDialogBodyLevelWrapper {
    width: 100%;
}

#CybotCookiebotDialogBodyEdgeMoreDetails {
    display: none;
}

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails {
    display: flex;
    justify-content: flex-end;
    margin: 1.5em 2em .5em 0;
}

#CybotCookiebotDialog.CybotEdge[dir=rtl] #CybotCookiebotDialogBodyEdgeMoreDetails {
    margin-left: 1.125em;
    margin-right: 0;
}

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a {
    align-items: center;
    color: #141414 !important;
    display: flex;
    font-weight: 600;
    text-decoration: none;
}

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a:after {
    border-style: solid;
    border-width: .15em .15em 0 0;
    color: #141414 !important;
    content: "";
    display: block;
    height: .563em;
    margin-left: 1em;
    transform: rotate(45deg);
    width: .563em;
}

#CybotCookiebotDialog.CybotEdge[dir=rtl] #CybotCookiebotDialogBodyEdgeMoreDetails a:after {
    margin-left: 0;
    margin-right: 1em;
    transform: rotate(225deg);
}

#CybotCookiebotDialogBodyLevelButtons {
    width: 100%;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-shrink: 0;
    height: 32px;
    position: relative;
    width: 57px;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper:hover input[type=checkbox]:not(:disabled)+.CybotCookiebotDialogBodyLevelButtonSlider {
    opacity: .85;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input[type=checkbox].CybotCookiebotDialogBodyLevelButton {
    opacity: 0;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: #141414;
    border-radius: 32px;
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color .4s;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider:before {
    background-color: #ffffff;
    border-radius: 50%;
    bottom: 4px;
    content: "";
    height: 24px;
    left: 4px;
    position: absolute;
    transition: transform .4s;
    width: 24px;
}

#CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: #222 !important;
}

#CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider:before,
#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogTabContent input+.CybotCookiebotDialogBodyLevelButtonSlider:before {
    background-color: #ffffff;
    transform: translateX(26px);
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogTabContent input:checked+.CybotCookiebotDialogBodyLevelButtonSlider:before {
    transform: translateX(0);
}

#CybotCookiebotDialog form input[type=checkbox][disabled]:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: #D6D6D6;
    pointer-events: none;
}

#CybotCookiebotDialogBodyLevelButtonsSelectPane {
    border-bottom: 1px solid #D6D6D6;
    padding: 1em 0;
}

#CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    text-align: center;
    width: 100%;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogBodyLevelButtonWrapper+.CybotCookiebotDialogBodyLevelButtonWrapper label {
    font-size: .875em;
    font-style: italic;
    font-weight: 400;
    letter-spacing: .15px;
    padding-bottom: 0;
}

#CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonDescription {
    font-weight: 700;
}

#CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover {
    color: #141414 !important;
}

@media screen and (min-width:601px) {
    #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsSelectPane {
        padding: 0;
    }

    #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper {
        flex-direction: column;
        padding: 1.5em 1em;
        width: 25%;
    }

    #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper:not(:last-of-type) {
        border-right: 1px solid #D6D6D6;
    }

    #CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper:not(:last-of-type) {
        border-left: 1px solid #D6D6D6;
        border-right: none;
    }

    #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonLabel {
        margin-bottom: .75em;
    }

    #CybotCookiebotDialogTabContent input:focus+.CybotCookiebotDialogBodyLevelButtonSlider {
        box-shadow: 0 0 1px #222 !important;
    }
}

@media screen and (min-width:1280px) {
    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyLevelButtonsSelectPane {
        border-bottom: none;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper {
        border: none;
        flex-direction: row;
        margin: .5em 2.5em 0 0;
        padding: 0;
        width: auto;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonLabel {
        margin-bottom: 0;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyLevelWrapper {
        width: auto;
    }

    #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogBodyLevelButtonLabel {
        margin-right: 1em;
    }

    #CybotCookiebotDialog.CybotEdge[dir=rtl] .CybotCookiebotDialogBodyLevelButtonLabel {
        margin-left: 1em;
        margin-right: 0;
    }

    #CybotCookiebotDialog.CybotEdge[dir=rtl] #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper {
        border-left: none;
        margin: .5em 0 0 2.5em;
    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails {
        display: inline-flex;
        height: 2em;
        margin: 0;
    }
}

#CybotCookiebotDialog input[type=checkbox] {
    height: 1.5em;
    margin-right: .5em;
    width: 1.5em;
}

#CybotCookiebotDialog[dir=rtl] input[type=checkbox] {
    margin-left: .5em;
    margin-right: 0;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper form {
    height: 100%;
    width: 100%;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input[type=checkbox] {
    height: 100%;
    margin-right: 0;
    width: 100%;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentIABv2Tabs input[type=checkbox] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    background-color: #ffffff;
    border: 2px solid #D6D6D6;
    border-radius: 4px;
    position: relative;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentIABv2Tabs input[type=checkbox]:not(:checked):hover {
    border-color: #141414;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentIABv2Tabs input[type=checkbox]:checked {
    background-color: #222 !important;
    border-color: #222 !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentIABv2Tabs input[type=checkbox]:checked:after {
    border-bottom: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    content: "";
    display: inline-block;
    height: 50%;
    left: 50%;
    position: absolute;
    top: calc(50% - 2px);
    transform: translate(-50%, -50%) rotate(45deg);
    width: 25%;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs {
    padding: 0 .625em;
    width: 100%;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogDetailBodyContentIABv2TabsIntro {
    padding: 1.125em 1.125em .25em;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogDetailBodyContentIABv2TabsIntro:nth-child(3) {
    margin-bottom: 1.5em;
}

.CybotCookiebotDialogDetailBodyContentCookieContainerHeader {
    display: flex;
    justify-content: space-between;
}

.CybotCookiebotDialogDetailBodyContentCookieContainerButton:before,
.CybotCookiebotDialogDetailBodyContentIABv2Tab:before {
    border-style: solid;
    border-width: .15em .15em 0 0;
    content: "";
    display: inline-block;
    height: .5em;
    left: 0;
    margin-right: 1.875em;
    position: relative;
    top: .625em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: .5em;
}

#CybotCookiebotDialog[dir=rtl] .CybotCookiebotDialogDetailBodyContentCookieContainerButton:before,
#CybotCookiebotDialog[dir=rtl] .CybotCookiebotDialogDetailBodyContentIABv2Tab:before {
    margin-left: 1.875em;
    margin-right: 0;
}

.CybotCookiebotDialogDetailBodyContentCookieContainerButton.CybotCookiebotDialogCollapsed:before,
.CybotCookiebotDialogDetailBodyContentIABv2Tab.CybotCookiebotDialogCollapsed:before {
    top: .25em;
    transform: rotate(-225deg);
}

#CybotCookiebotDialog .CookieCard {
    border-bottom: 1px solid #D6D6D6;
    padding: 1.5em 0;
}

#CybotCookiebotDialog .CookieCard:not(:first-child) {
    padding-top: 1.5em;
}

#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentIABContainer .CookieCard {
    border-bottom: none;
    border-top: 1px solid #D6D6D6;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CookieCard:last-of-type {
    margin-bottom: 1em;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CookieCard:first-of-type {
    padding-top: 1.625em;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab {
    border: 1px solid transparent;
    font-weight: 700;
    line-height: 1.5em;
    padding-right: .75em;
    user-select: none;
}

#CybotCookiebotDialog[dir=rtl] .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
#CybotCookiebotDialog[dir=rtl] .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab {
    padding-left: .75em;
    padding-right: 0;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
    color: #141414 !important;
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieTypeIntro,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer,
#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CollapseCard {
    padding-top: 1em;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CollapseCard>div:not(.CybotCookiebotDialogBodyLevelButtonIABWrapper) {
    letter-spacing: .3px;
    line-height: 1.6em;
    margin-left: 2.9em;
}

#CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogDetailBodyContentIABv2CardIntro {
    letter-spacing: .3px;
    line-height: 1.6em;
    margin-left: 2.9em;
    padding-top: 1em;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CollapseCard>div:not(.CybotCookiebotDialogBodyLevelButtonIABWrapper),
#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CybotCookiebotDialogDetailBodyContentIABv2CardIntro {
    margin-left: 0;
    margin-right: 2.9em;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyEmptyCategoryMessage {
    border: 1px solid #D6D6D6;
    border-radius: .5em;
    font-weight: 600;
    margin: 1em 0;
    padding: 1em 1.5em;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieGroup {
    border: 1px solid #D6D6D6;
    border-radius: .5em;
    margin-bottom: .5em;
    padding: 1em;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieGroup:not(.open):hover {
    background-color: rgba(0, 0, 0, .05);
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieGroup:last-of-type {
    margin-bottom: 0;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider {
    display: block;
    font-weight: 700;
    position: relative;
    text-decoration: none;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider>div {
    pointer-events: none;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount) {
    color: #141414 !important;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider.CybotCookiebotDialogCollapsed:after,
#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:after {
    border-style: solid;
    border-width: .15em .15em 0 0;
    content: "";
    display: inline-block;
    height: .5em;
    position: absolute;
    right: 1em;
    top: .5em;
    transform: rotate(-45deg);
    width: .5em;
}

#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider.CybotCookiebotDialogCollapsed:after,
#CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:after {
    left: 1em;
    right: auto;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider.CybotCookiebotDialogCollapsed:after {
    transform: rotate(-225deg);
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieLink {
    align-items: center;
    display: inline-flex;
    padding-top: 1em;
    position: relative;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfo {
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid #D6D6D6;
    border-radius: 1em;
    margin-bottom: .5em;
    padding: 1em;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfo:last-of-type {
    margin-bottom: 0;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfo.CybotCookiebotDialogShow {
    display: block;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfo.CybotCookiebotDialogShow:first-of-type {
    margin-top: 3.125em;
    position: relative;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfo.CybotCookiebotDialogShow:first-of-type:before {
    border-top: 1px solid #D6D6D6;
    content: "";
    left: 0;
    position: absolute;
    top: -1.5em;
    width: 100%;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfoTitle {
    display: block;
    font-weight: 700;
    line-height: 1.6em;
    margin-bottom: .5em;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfoDescription {
    display: block;
    letter-spacing: .3px;
    padding-bottom: 1em;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfoFooter {
    border-top: 1px solid #D6D6D6;
    display: flex;
    flex-wrap: wrap;
    padding-top: .5em;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfoFooterContent {
    margin-top: .5em;
    width: 100%;
}

@media screen and (min-width:1280px) {

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes,
    #CybotCookiebotDialogDetailBodyContentIABv2Tabs {
        padding: 0 1.125em;
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer {
        margin-top: 2em;
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer,
    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CollapseCard {
        margin-left: 3em;
        padding-top: .5em;
    }

    #CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer,
    #CybotCookiebotDialog[dir=rtl] #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CollapseCard {
        margin-left: 0;
        margin-right: 3em;
    }

    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CollapseCard>div:not(.CybotCookiebotDialogBodyLevelButtonIABWrapper) {
        margin-left: 0;
    }

    #CybotCookiebotDialog .CookieCard {
        padding: 1.125em 0 1.5em;
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CookieCard:last-of-type {
        margin-bottom: 1.5em;
        padding-bottom: 2em;
    }

    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CookieCard:first-of-type {
        padding-top: 1.125em;
    }

    #CybotCookiebotDialogDetailBodyContentIABv2Tabs .CookieCard:last-of-type {
        padding-bottom: 1.125em;
    }

    #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
        padding-left: 3em;
    }

    #CybotCookiebotDialog[dir=rtl] .CookieCard .CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
        padding-left: 0;
        padding-right: 3em;
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyEmptyCategoryMessage {
        margin: 0 0 1.5em;
        padding: 1.5em;
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieGroup,
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfo {
        padding: 1.5em;
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfo.CybotCookiebotDialogShow:first-of-type {
        margin-top: 3.75em;
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfo.CybotCookiebotDialogShow:first-of-type:before {
        top: -2em;
    }

    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieInfoFooterContent {
        width: 50%;
    }
}

#CybotCookiebotDialog .CybotCookiebotDialogHide {
    display: none;
}

.CybotExpandLink {
    position: relative;
}

.CybotExpandLink.CybotCookiebotDialogCollapsed:after,
.CybotExpandLink:after {
    border-style: solid;
    border-width: .15em .15em 0 0;
    content: "";
    height: .5em;
    position: absolute;
    right: 1em;
    top: .3em;
    transform: rotate(-45deg);
    width: .5em;
}

#CybotCookiebotDialog[dir=rtl] .CybotExpandLink.CybotCookiebotDialogCollapsed:after,
#CybotCookiebotDialog[dir=rtl] .CybotExpandLink:after {
    left: 0;
    right: auto;
}

.CybotExpandLink.CybotCookiebotDialogCollapsed:after {
    transform: rotate(-225deg);
}

#CybotCookiebotDialog .CybotExternalLinkArrow {
    margin-left: .625em;
}

#CybotCookiebotDialog[dir=rtl] .CybotExternalLinkArrow {
    margin-left: 0;
    margin-right: .625em;
    transform: scaleX(-1);
}

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:not(:disabled):not(.disabled),
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:not(:disabled):not(.disabled),
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input[type=checkbox],
#CybotCookiebotDialog button,
#CybotCookiebotDialog input,
#CybotCookiebotDialog label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) {
    cursor: pointer;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input[type=checkbox]:disabled {
    pointer-events: none;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapperDisabled {
    cursor: not-allowed;
}

#CybotCookiebotDialog dt {
    word-break: break-all;
}

#CybotCookiebotDialog fieldset {
    border: none;
    width: 100%;
}

#CybotCookiebotDialog fieldset #CybotCookiebotDialogBodyFieldsetInnerContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

#CybotCookiebotDialog legend.visuallyhidden {
    display: block;
}

#CybotCookiebotDialog .visuallyhidden {
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialogDetailBodyContentTextAbout a {
    color: #141414 !important;
    font-weight: 600;
    text-decoration: none;
}

#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a:hover,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink:hover,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a:hover,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a:hover,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a:hover,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink:hover,
#CybotCookiebotDialogDetailBodyContentTextAbout a:hover {
    opacity: .85;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input:focus-visible+span,
#CybotCookiebotDialog :focus-visible,
#CybotCookiebotDialog:focus-visible {
    outline: none;
}

@media screen and (min-width:601px) {

    #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input:focus-visible+span,
    #CybotCookiebotDialog :focus-visible {
        outline: 2px solid #222 !important;
    }
}

@media not all and (min-resolution:.001dpcm) {
    @media screen and (min-width:1280px) {
        #CybotCookiebotDialog #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
            display: block;
        }
    }
}

#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw{
    border-color: #222 !important;
}

#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
    background-color: #222!important;
    border-color: #222!important;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg{
    fill: #222!important;
}

#CookiebotWidget .CookiebotWidget-consent-details button{
    color: #222!important;
}
